body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@-webkit-keyframes uparrow {
  0% { -webkit-transform: translateY(0); opacity: 0.4 }
  100% { -webkit-transform: translateY(-0.4em); opacity: 0.9 }
}
@-webkit-keyframes downarrow {
  0% { -webkit-transform: translateY(0); opacity: 0.4 }
  100% { -webkit-transform: translateY(0.4em); opacity: 0.9 }
}

.arrow {
  border-color:transparent;
  border-style:solid;
  border-width:0 2em;
  display:block;
  height:0;
  margin:10em auto;
  opacity:0.4;
  text-indent:-9999px;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  width:0;
}
.up {
  -webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
  border-bottom:2em solid #00b6f1;
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
  border-top:2em solid #00b6f1;
}

